/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: italic;
	font-weight: 300;
	src: local('Raleway Light Italic'), local('Raleway-LightItalic'), url('assets/fonts/raleway/1Ptpg8zYS_SKggPNyCgw5qN_DNCb71ka4ZiO.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: italic;
	font-weight: 300;
	src: local('Raleway Light Italic'), local('Raleway-LightItalic'), url('assets/fonts/raleway/1Ptpg8zYS_SKggPNyCgw5qN_AtCb71ka4Q.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: italic;
	font-weight: 400;
	src: local('Raleway Italic'), local('Raleway-Italic'), url('assets/fonts/raleway/1Ptsg8zYS_SKggPNyCg4Q4FqL_KWxWMT.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: italic;
	font-weight: 400;
	src: local('Raleway Italic'), local('Raleway-Italic'), url('assets/fonts/raleway/1Ptsg8zYS_SKggPNyCg4TYFqL_KWxQ.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: italic;
	font-weight: 500;
	src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'), url('assets/fonts/raleway/1Ptpg8zYS_SKggPNyCgwvqJ_DNCb71ka4ZiO.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: italic;
	font-weight: 500;
	src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'), url('assets/fonts/raleway/1Ptpg8zYS_SKggPNyCgwvqJ_AtCb71ka4Q.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 300;
	src: local('Raleway Light'), local('Raleway-Light'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwIYqWqhPANqczVsq4A.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 300;
	src: local('Raleway Light'), local('Raleway-Light'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwIYqWqZPANqczVs.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	src: local('Raleway'), local('Raleway-Regular'), url('assets/fonts/raleway/1Ptug8zYS_SKggPNyCMIT4ttDfCmxA.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	src: local('Raleway'), local('Raleway-Regular'), url('assets/fonts/raleway/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 500;
	src: local('Raleway Medium'), local('Raleway-Medium'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwN4rWqhPANqczVsq4A.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 500;
	src: local('Raleway Medium'), local('Raleway-Medium'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwN4rWqZPANqczVs.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwPIsWqhPANqczVsq4A.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwPIsWqZPANqczVs.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	src: local('Raleway Bold'), local('Raleway-Bold'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwJYtWqhPANqczVsq4A.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	src: local('Raleway Bold'), local('Raleway-Bold'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwJYtWqZPANqczVs.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 800;
	src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwIouWqhPANqczVsq4A.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 800;
	src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwIouWqZPANqczVs.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 900;
	src: local('Raleway Black'), local('Raleway-Black'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwK4vWqhPANqczVsq4A.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 900;
	src: local('Raleway Black'), local('Raleway-Black'), url('assets/fonts/raleway/1Ptrg8zYS_SKggPNwK4vWqZPANqczVs.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
