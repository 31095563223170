$main: #36a3f7;
//$main: #0078db;
//$main: #0366d6;
$main-light: #33e4ff;
$main-vlight: lighten(#4a8abc, 90%);
$secundary: #23ad44;
$background: #f3f5f8;
$red: #fa004c;
$red-light: lighten(#fa004c, 100%);
$blue: #4a8abc;
$blue-light: lighten(#4a8abc, 100%);
$purple: #5c5cfb;
$purple-light: lighten(#5c5cfb, 100%);
$yellow: #ebb200;
$yellow-light: lighten(#ebb200, 100%);
$green: #7dc306;
$green-light: lighten(#7dc306, 60%);
$cyan:   #17a2b8 !default;
$orange: #f37f64;

.cabs,
.center_absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.box-shadow {
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
}
.p-l-10 {
    padding-left: 10px;
}

.scroll {

	@media only screen and (min-width: 400px) {
		::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 16px;
			height: 16px;
			border-radius: 10px;
			background-color: rgba(0, 0, 0, 0.0);
		}

		::-webkit-scrollbar-thumb {
			border: 4px solid rgba(0, 0, 0, 0);
			-webkit-border-radius: 7px;
			background-clip: padding-box;
			width: 8px;
			height: 8px;
			border-radius: 10px;
			background-color: rgba(0, 0, 0, .2);
			-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .0);
		}
	}
}

@-webkit-keyframes fadein {
	from { opacity: 0.4; }
	to { opacity: 1; }
}

@-moz-keyframes fadein {
	from { opacity: 0.4; }
	to { opacity: 1; }
}

@keyframes fadein {
	from { opacity: 0.4; }
	to { opacity: 1; }
}

.fadein-animation {
	-webkit-animation: fadein 1s ease-in alternate infinite;
	-moz-animation: fadein 1s ease-in alternate infinite;
	animation: fadein 1s ease-in alternate infinite;
}
