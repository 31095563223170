@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
.cabs, .circle-avatar img, .circle-avatar.d-2 span, .circle-avatar.d-4 span, .header-search .header-icon:before,
.center_absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.box-shadow {
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08); }

.p-l-10 {
  padding-left: 10px; }

@media only screen and (min-width: 400px) {
  .scroll ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0); }
  .scroll ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    -webkit-border-radius: 7px;
    background-clip: padding-box;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0); } }

@-webkit-keyframes fadein {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0.4; }
  to {
    opacity: 1; } }

.fadein-animation {
  -webkit-animation: fadein 1s ease-in alternate infinite;
  -moz-animation: fadein 1s ease-in alternate infinite;
  animation: fadein 1s ease-in alternate infinite; }

body,
html {
  font-size: 13px;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Raleway', sans-serif;
  background: #f3f5f8;
  padding: 0px;
  margin: 0px; }

app-pages {
  z-index: 3;
  display: flex;
  flex-direction: column;
  height: 100%; }

app-header {
  flex: 0;
  display: block; }

.main-page {
  overflow: hidden;
  flex: 1;
  display: flex; }
  .main-page router-outlet ~ .ng-star-inserted {
    display: block;
    width: 100%;
    flex: 1;
    height: 100%;
    transition: 300ms ease-out;
    background: #f3f5f8; }
  .main-page.menu-open router-outlet ~ .ng-star-inserted {
    transform: translateX(100%);
    transition: 300ms ease-out; }
  @media only screen and (max-width: 1023px) {
    .main-page.menu-fast-open {
      margin-bottom: 54px; } }

.max-width {
  max-width: 1024px;
  width: 100%;
  display: table;
  margin: auto; }

.none {
  display: none; }

.page-flex {
  flex: 1;
  height: 100%;
  overflow: auto;
  transform: translate3d(0px, 0px, 0px);
  display: flex;
  flex-direction: column;
  background: #f3f5f8;
  width: 100%; }
  .page-flex .flex-footer,
  .page-flex .flex-header {
    flex: 0;
    width: 100%; }
  .page-flex .flex-body {
    flex: 1;
    overflow: auto;
    position: relative;
    transform: translate3d(0px, 0px, 0px); }
  @media only screen and (max-width: 1023px) {
    .page-flex {
      position: fixed !important;
      z-index: 210 !important; }
      .page-flex.no-over {
        position: relative !important;
        z-index: auto !important; } }

.page-background {
  padding-bottom: 50px;
  overflow-y: auto; }
  @media only screen and (min-width: 1023px) {
    .page-background {
      padding-top: 20px; } }
  @media only screen and (max-width: 1023px) {
    .page-background {
      background: white; }
      .page-background .card {
        box-shadow: none !important; } }

.color-green {
  color: #7dc306; }

*:focus {
  outline: none !important; }

.over-screen {
  position: absolute;
  top: -9000000px;
  left: -9000000px; }

.btn-circle-action {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #36a3f7;
  text-align: center;
  font-size: 17px;
  color: white; }

.btn {
  border: 0px;
  padding: 10px; }
  .btn.btn-primary {
    background: #36a3f7; }
  .btn.btn-secundary {
    background: #000;
    color: white;
    font-weight: 500; }
  .btn.btn-round {
    border-radius: 100px;
    padding: 8px 15px; }
  .btn.btn-border {
    border: 1px solid #eee; }
  .btn.btn-icon {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    padding: 0px;
    background-color: white;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    color: #222;
    margin-right: 5px; }

select:focus,
input:focus {
  outline: none; }

.bg-white {
  color: #58666e !important; }

.md-btn,
.md-btn.md-fab,
.md-list-item-content {
  overflow: hidden; }

.btn-shadow {
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2); }

.btn-shadow-2 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.bg-red {
  color: white !important;
  background-color: #fa004c !important; }

ul,
li {
  margin: 0px;
  padding: 0px; }

.card {
  background-color: white;
  border: none;
  width: 100%;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 1px 0 rgba(59, 89, 178, 0.08), 0 4px 14px rgba(59, 89, 178, 0.26);
  border-radius: .25rem; }
  .card .card-title {
    font-weight: bold;
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    margin: 0px; }

a {
  color: #4a8abc; }

.tabs {
  width: 100%;
  border-bottom: 1px solid #eee; }
  .tabs .tab {
    margin-top: 5px;
    padding: 10px 20px;
    padding-bottom: 15px;
    margin-bottom: -1px;
    display: inline-block;
    font-weight: 600;
    color: #888; }
    .tabs .tab.active {
      color: #222;
      border-bottom: 4px solid #36a3f7; }

.btn-group.btn-selects .btn {
  border: 1px solid #ddd;
  width: 35px;
  color: #777;
  display: inline-block;
  line-height: 35px;
  height: 35px;
  text-align: center;
  padding: 0px;
  background: #fff; }
  .btn-group.btn-selects .btn.active {
    background: #000;
    color: #fff; }

@media only screen and (min-width: 1023px) {
  .show-mobile {
    display: none !important; } }

@media only screen and (max-width: 1022.89px) {
  .hide-mobile {
    display: none !important; } }

.btn-group-checked .btn {
  border-radius: 0px;
  position: relative;
  font-weight: 600;
  color: #888;
  margin: 0px 5px;
  -webkit-transition: 0.2s color;
  -moz-transition: 0.2s color;
  -ms-transition: 0.2s color;
  -o-transition: 0.2s color;
  transition: 0.2s color; }
  .btn-group-checked .btn:hover {
    color: #000;
    -webkit-transition: 0.2s color;
    -moz-transition: 0.2s color;
    -ms-transition: 0.2s color;
    -o-transition: 0.2s color;
    transition: 0.2s color; }
  .btn-group-checked .btn.active {
    background: #000;
    color: white; }
  .btn-group-checked .btn.active2 {
    padding: 4px;
    border: 2px solid #222;
    position: relative; }
    .btn-group-checked .btn.active2:after {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      z-index: 1;
      font-weight: 900;
      width: 25px;
      height: 25px;
      line-height: 25px;
      top: 0px;
      right: 0px;
      transform: translate(50%, -50%);
      border-radius: 100px;
      text-align: center;
      display: block;
      position: absolute;
      background-color: #222;
      color: white;
      opacity: 1.0;
      transition: 0.1s all; }

.btn-group-checked.btn-group-border .btn {
  border: 1px solid #ddd;
  border-left: none;
  margin: 0px; }
  .btn-group-checked.btn-group-border .btn:first-child {
    border-left: 1px solid #ddd; }

.initials-letter {
  border-radius: 100%;
  background: #222222;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle; }
  .initials-letter.initials-small {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 13px; }
  .initials-letter.initials-xs {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 8px;
    vertical-align: middle; }
  .initials-letter.size-90 {
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 45px;
    vertical-align: middle; }
  .initials-letter.initials-big {
    width: 125px;
    height: 125px;
    line-height: 125px;
    font-size: 62.5px; }
  .initials-letter.initials-md {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 25px; }

.scroll-height {
  overflow-y: auto;
  max-height: 100%; }

.circle-avatar {
  width: 125px;
  height: 125px;
  background-color: #eee;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin: auto;
  display: inline-block;
  vertical-align: middle; }
  .circle-avatar img {
    max-width: 100%;
    max-height: 100%; }
  .circle-avatar.d-2 span {
    line-height: 0px;
    display: block;
    max-width: 50%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .circle-avatar.d-2 span img {
      height: 100%;
      max-width: none; }
    .circle-avatar.d-2 span:nth-child(1) {
      left: 25%; }
    .circle-avatar.d-2 span:nth-child(2) {
      left: 75%; }
  .circle-avatar.d-4 span {
    line-height: 0px;
    display: block;
    max-width: 50%;
    max-height: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .circle-avatar.d-4 span img {
      height: 100%;
      max-width: none; }
    .circle-avatar.d-4 span:nth-child(1), .circle-avatar.d-4 span:nth-child(3) {
      left: 25%; }
    .circle-avatar.d-4 span:nth-child(2), .circle-avatar.d-4 span:nth-child(4) {
      left: 75%; }
    .circle-avatar.d-4 span:nth-child(1), .circle-avatar.d-4 span:nth-child(2) {
      top: 25%; }
    .circle-avatar.d-4 span:nth-child(3), .circle-avatar.d-4 span:nth-child(4) {
      top: 75%; }
  .circle-avatar.normal {
    width: 40px;
    height: 40px; }
  .circle-avatar.small {
    width: 26px;
    height: 26px; }

.user-miniprofile {
  position: fixed;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  padding: 10px;
  background: white;
  max-width: 240px;
  border-radius: 3px; }

.user-container .user-image .initials-letter {
  width: 50px;
  height: 50px;
  line-height: 47px;
  font-size: 20px;
  border: 2px solid white; }

.table {
  display: table;
  margin-bottom: 0px;
  background: transparent;
  color: inherit; }
  .table .table {
    background: transparent; }

.td-max-width-0, .td.td-max-width-0 {
  max-width: 0px; }

.td-min {
  width: 1%; }

.tr {
  display: table-row; }

.td {
  display: table-cell;
  vertical-align: middle; }
  .td.td-min {
    width: 1%; }
  .td.td-overflow-text {
    width: 100%;
    max-width: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.dashed {
  border-bottom: 1px dashed #36a3f7;
  display: inline-block;
  padding-left: 5px; }

.text-light {
  color: #888 !important; }

.text-green {
  color: #7dc306; }

.text-orange {
  color: #f37f64; }

.text-main {
  color: #36a3f7; }

.text-ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.checkbox-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer; }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .checkbox-container .text {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    color: #555; }
  .checkbox-container .checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #bbb;
    position: relative;
    transition: 0.1s all;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px; }
    .checkbox-container .checkbox:after {
      content: "";
      display: block;
      opacity: 0;
      transition: 0.1s all;
      position: relative;
      left: 5px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid #36a3f7;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    .checkbox-container .checkbox.quad {
      border-radius: 4px; }
  .checkbox-container input:checked ~ .checkbox {
    border-color: #36a3f7;
    transition: 0.1s all; }
    .checkbox-container input:checked ~ .checkbox:after {
      opacity: 1.0; }
    .checkbox-container input:checked ~ .checkbox ~ .text {
      color: #36a3f7; }
  .checkbox-container:hover {
    color: #36a3f7; }
  .checkbox-container.checkbox-quad .checkbox {
    border-radius: 4px; }
  .checkbox-container.checkbox-small .checkbox {
    width: 14px;
    height: 14px;
    vertical-align: middle; }
  .checkbox-container.checkbox-small input:checked ~ .checkbox:after {
    left: 3px;
    top: 0px;
    width: 4px;
    height: 8px; }

.field-container {
  position: relative;
  margin-bottom: 5px;
  display: block;
  width: 100%; }
  .field-container input {
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    background: #eee;
    padding: 10px 15px;
    width: 100%;
    border: 2px solid transparent; }
    .field-container input:focus {
      border: 2px solid #36a3f7; }
  .field-container textarea {
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    background: #eee;
    padding: 10px 15px;
    width: 100%;
    min-height: 90px;
    box-sizing: border-box;
    resize: none;
    border: 2px solid transparent; }
    .field-container textarea:focus {
      border: 2px solid #36a3f7; }
  .field-container .input {
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    background: #eee;
    padding: 10px 15px;
    width: 100%;
    display: inline-block; }
  .field-container .input-space {
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    width: 100%;
    display: inline-block; }
  .field-container .user {
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    background: #eee;
    padding: 10px 15px;
    width: 100%;
    display: inline-block; }
  .field-container .name {
    z-index: 1;
    position: absolute;
    line-height: 30px;
    font-weight: 600;
    font-size: 12px;
    padding-left: 0px;
    color: #222;
    top: 0px;
    left: 0px;
    width: 100%;
    text-transform: uppercase; }
  .field-container.no-text input {
    margin-top: 0px; }
  .field-container.no-text textarea {
    margin-top: 0px; }
  .field-container.no-text .user {
    margin-top: 0px; }
  .field-container.design1 {
    position: relative;
    height: 50px;
    margin-bottom: 5px;
    display: block;
    width: 100%; }
    .field-container.design1 input {
      border: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 22px 10px 0px 10px;
      border-bottom: 2px solid #777;
      color: #777;
      font-weight: 500; }
      .field-container.design1 input:focus {
        border-color: #36a3f7; }
        .field-container.design1 input:focus ~ .name {
          color: #36a3f7; }
    .field-container.design1 .name {
      z-index: 1;
      position: absolute;
      line-height: 30px;
      font-weight: 600;
      font-size: 12px;
      padding-left: 10px;
      color: #000;
      text-transform: uppercase; }
  .field-container.price input {
    padding-left: 29px;
    padding-right: 5px; }
  .field-container.price:after {
    content: 'R$';
    position: absolute;
    top: 36px;
    left: 10px;
    z-index: 1;
    font-weight: 600;
    opacity: 0.5; }
  .field-container.area input {
    padding-right: 29px;
    padding-left: 5px;
    text-align: right; }
  .field-container.area:after {
    content: 'm²';
    position: absolute;
    top: 36px;
    right: 10px;
    z-index: 1;
    font-weight: 600;
    opacity: 0.5; }
  .field-container.error-input .name {
    color: crimson; }
  .field-container.error-input .input,
  .field-container.error-input input,
  .field-container.error-input textarea {
    border: 2px solid crimson; }

.text-error-form {
  text-align: center;
  font-weight: 600;
  background: crimson;
  color: white;
  padding: 5px;
  font-size: 14px; }

.name-input {
  z-index: 1;
  line-height: 30px;
  font-weight: 600;
  font-size: 12px;
  padding-left: 0px;
  color: #222;
  width: 100%;
  text-transform: uppercase; }

.autocomplete-list {
  position: absolute;
  z-index: 10;
  background: white;
  width: 100%;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2); }
  .autocomplete-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: block;
    font-weight: 500;
    font-size: 12px; }
    .autocomplete-list li .active {
      padding: 10px;
      border-bottom: 1px solid #508abd;
      background: #508abd;
      color: white; }

.select-container {
  position: relative;
  margin-bottom: 5px;
  display: block;
  width: 100%; }
  .select-container:after {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    font-weight: 900;
    top: 37px;
    right: 19px;
    width: 0;
    height: 0;
    color: #222;
    z-index: 1; }
  .select-container select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    background: #eee;
    padding: 10px 15px;
    width: 100%;
    position: relative; }
  .select-container .name {
    z-index: 1;
    position: absolute;
    line-height: 30px;
    font-weight: 600;
    font-size: 12px;
    padding-left: 0px;
    color: #000;
    top: 0px;
    left: 0px;
    text-transform: uppercase; }
  .select-container.no-name select {
    margin-top: 0px; }
  .select-container.no-name:after {
    top: 10px; }
  .select-container.design1 {
    position: relative;
    height: 50px;
    margin-bottom: 5px;
    display: block;
    width: 100%; }
    .select-container.design1:after {
      position: absolute;
      font-family: "Font Awesome 5 Free";
      content: "\f107";
      font-weight: 900;
      top: 24px;
      right: 15px;
      width: 0;
      height: 0;
      color: #777; }
    .select-container.design1 select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      border: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 22px 10px 0px 10px;
      border-bottom: 2px solid #777;
      color: #777;
      font-weight: 600;
      background-color: transparent;
      border-radius: 0px;
      z-index: 1; }
      .select-container.design1 select:focus {
        border-color: #36a3f7; }
        .select-container.design1 select:focus ~ .name {
          color: #36a3f7; }
    .select-container.design1 .name {
      position: absolute;
      line-height: 30px;
      font-weight: 600;
      font-size: 12px;
      padding-left: 10px;
      color: #000;
      text-transform: uppercase; }
  .select-container.error-input .name {
    color: crimson; }
  .select-container.error-input select {
    box-shadow: 0px 0px 2px 1px crimson; }

.img-border {
  padding: 4px;
  display: table;
  border: 1px solid #eee;
  margin: auto;
  border: 1px solid #eee; }

.img-circle {
  position: relative;
  width: 44px;
  height: 44px;
  overflow: hidden;
  border-radius: 200px;
  background-color: #eee; }
  .img-circle.img-90 {
    width: 90px;
    height: 90px; }
  .img-circle img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

small {
  display: block;
  font-size: 12px;
  color: #666;
  font-weight: 500; }

.field-title {
  z-index: 1;
  font-weight: 600;
  font-size: 12px;
  color: #000;
  text-transform: uppercase; }

.title-form {
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 20px; }

.subtitle-form {
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 7px;
  color: #000;
  padding-bottom: 9px;
  color: #418cce;
  font-weight: 500;
  margin-top: 20px; }

.loading-opacity {
  opacity: 0.5; }

.loading-icon {
  display: block;
  position: absolute;
  z-index: 10;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px; }
  .loading-icon .load {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .loading-icon .load div {
    position: absolute;
    border: 4px solid #36a3f7;
    opacity: 1;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: load 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
  .loading-icon .load div:nth-child(2) {
    animation-delay: -0.5s; }

@keyframes load {
  0% {
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    width: 58px;
    height: 58px;
    opacity: 0; } }

.loading-icon-ring {
  margin: auto;
  display: table;
  height: 43px; }
  .loading-icon-ring:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #36a3f7;
    border-color: #36a3f7 transparent #36a3f7 transparent;
    animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .loading-icon-ring.big:after {
    content: " ";
    width: 60px;
    height: 60px;
    border-width: 5px; }

.header-search {
  margin: 0px;
  margin-bottom: 20px; }
  .header-search .title {
    font-weight: 600;
    font-size: 18px; }
    .header-search .title .number {
      position: relative;
      top: -10px;
      font-size: 16px;
      color: #666;
      padding-left: 5px;
      display: inline-block; }
  .header-search .header-icon {
    color: #666;
    display: inline-block;
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-align: center;
    cursor: pointer; }
    .header-search .header-icon i {
      position: relative;
      z-index: 2; }
    .header-search .header-icon:before {
      content: '';
      background: rgba(0, 0, 0, 0.1);
      width: 0px;
      height: 0px;
      position: absolute;
      border-radius: 100%;
      transition: 0.2s all;
      z-index: 1; }
    .header-search .header-icon:hover:before {
      width: 40px;
      height: 40px;
      transition: 0.2s all; }

.hidden {
  visibility: hidden; }

router-outlet {
  position: absolute;
  display: block; }

.alert-light {
  background: #efefef;
  font-weight: 600; }

@media only screen and (max-width: 1200px) {
  .hide-l1200 {
    display: none; } }

.show-l1200 {
  display: none; }
  @media only screen and (max-width: 1200px) {
    .show-l1200 {
      display: inherit; } }

.actions-bottom {
  bottom: 0px;
  left: 0px;
  background: white;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 1px 0 rgba(59, 89, 178, 0.08), 0 4px 14px rgba(59, 89, 178, 0.06);
  z-index: 22; }
  .actions-bottom .btn-primary {
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0px;
    font-size: 12px; }

.click {
  cursor: pointer; }

.footer {
  color: #a7baca;
  font-weight: 600;
  font-size: 13px; }

.new {
  background: #0383df;
  color: white;
  font-size: 11px;
  padding: 2px 7px;
  display: inline-block;
  font-weight: 600;
  vertical-align: middle; }

.not-found {
  color: #888; }
  .not-found .icon {
    font-size: 80px; }

.select-clean {
  background: transparent;
  border: none;
  padding: 10px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 700;
  color: #36a3f7;
  font-size: 13px;
  margin: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #eee; }

.color-primary {
  color: #36a3f7; }

.modal-screen {
  background: rgba(0, 0, 0, 0.8);
  background: rgba(14, 14, 45, 0.89);
  background: rgba(0, 11, 23, 0.89);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 210;
  top: 0px;
  left: 0px;
  color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; }
  .modal-screen .icon-circle {
    width: 80px;
    height: 80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 4px solid #eee;
    position: relative;
    margin: auto;
    margin-bottom: 15px; }
    .modal-screen .icon-circle i {
      position: absolute;
      color: #eee;
      font-size: 36px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .modal-screen .btn-default {
    background: #111;
    color: white; }
  .modal-screen .dialog-content {
    max-height: 100%;
    width: 100%;
    overflow: auto;
    position: relative;
    padding: auto; }
    .modal-screen .dialog-content .text {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      padding: 10px; }
  .modal-screen .title {
    line-height: 30px;
    font-size: 23px;
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;
    padding: 0px 1rem;
    padding-top: 1rem; }
    .modal-screen .title:after {
      content: "";
      background: #36a3f7;
      width: 40px;
      height: 3px;
      display: block;
      margin: auto;
      margin-top: 7px; }
    @media only screen and (max-width: 1024px) {
      .modal-screen .title {
        text-align: left; } }
  .modal-screen .icon {
    font-size: 60px;
    padding: 0px;
    margin: auto; }
  .modal-screen .body-text {
    text-align: center;
    font-weight: 600;
    font-size: 16px; }
  .modal-screen .title {
    line-height: 36px;
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: 500; }
  .modal-screen .modal-box-text {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    line-height: 23px;
    display: block; }
  .modal-screen .i-close {
    position: absolute;
    color: white;
    top: 5px;
    right: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    line-height: 35px; }
    .modal-screen .i-close .display-back {
      display: none; }
    .modal-screen .i-close .display-close {
      display: block; }
  .modal-screen .modal-body {
    border-radius: 4px;
    background: #ffffff;
    width: 100%;
    max-width: 500px;
    align-items: center;
    justify-content: center;
    margin: auto; }
    .modal-screen .modal-body.pd {
      padding: 42px 86px; }
    .modal-screen .modal-body .i-close {
      top: 14px;
      right: 10px; }
  @media only screen and (max-width: 1024px) {
    .modal-screen.change-mobile-page .title {
      padding: 0px;
      line-height: 51px;
      font-size: 16px;
      padding-left: 44px;
      border-bottom: 2px solid #eee; }
      .modal-screen.change-mobile-page .title:after {
        display: none; }
    .modal-screen.change-mobile-page .modal-body {
      border-radius: 0px;
      height: 100% !important; }
      .modal-screen.change-mobile-page .modal-body .i-close {
        top: 12px;
        right: auto;
        left: 10px; }
        .modal-screen.change-mobile-page .modal-body .i-close .display-back {
          display: block;
          height: 25px; }
        .modal-screen.change-mobile-page .modal-body .i-close .display-close {
          display: none; } }

.image-rot90 {
  transform: translate(-50%, -50%) rotate(90deg) !important; }

.image-rot180 {
  transform: translate(-50%, -50%) rotate(180deg) !important; }

.image-rot270 {
  transform: translate(-50%, -50%) rotate(270deg) !important; }

.toast-bottom-left {
  left: 0px; }

.toast-error,
.toast-info,
.toast-success,
.toast-warning {
  background-image: none !important; }

.toast-container .toast {
  padding: 10px 20px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
  font-size: 0.95rem;
  letter-spacing: 0.2px;
  align-items: center;
  border: none;
  border-radius: 4px;
  /* bottom: 15px; */
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  left: 50%;
  margin-bottom: 10px;
  max-width: 640px;
  min-height: 47px;
  padding: 8px 24px;
  padding-right: 52px;
  /* position: fixed; */
  max-width: 90%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  justify-content: center;
  width: 100%;
  max-width: 100%;
  line-height: 20px;
  max-width: 100%;
  bottom: 0px;
  min-height: auto;
  padding: 10px 15px;
  border-radius: 0px;
  margin-bottom: 0px; }
  .toast-container .toast:hover {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149); }

.toast-message {
  font-weight: bold; }

.toast-bottom-right {
  bottom: 0px;
  width: 100%;
  right: 0px; }

.toast-error {
  background: crimson; }

.toast-info {
  background: #4a8abc; }

.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center center; }

.alert-dark {
  background: #222;
  color: white; }

.icon-close {
  display: inline-block;
  vertical-align: middle;
  padding: 3px;
  background: #6d6d6d;
  border-radius: 20px; }
  .icon-close:before {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve' fill='white'%3E%3Cg id='Icons' style='opacity:1.0;'%3E%3Cg id='close'%3E%3Cpolygon id='x' style='fill-rule:evenodd;clip-rule:evenodd;' points='18.717,6.697 17.303,5.283 12,10.586 6.697,5.283 5.283,6.697 10.586,12 5.283,17.303 6.697,18.717 12,13.414 17.303,18.717 18.717,17.303 13.414,12 '/%3E%3C/g%3E%3C/g%3E%3Cg id='Guides' style='display:none;'%3E%3C/g%3E%3C/svg%3E"); }
  .icon-close:hover {
    cursor: pointer;
    background: #fa004c; }

.icon-close-only {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve' fill='white'%3E%3Cg id='Icons' style='opacity:1.0;'%3E%3Cg id='close'%3E%3Cpolygon id='x' style='fill-rule:evenodd;clip-rule:evenodd;' points='18.717,6.697 17.303,5.283 12,10.586 6.697,5.283 5.283,6.697 10.586,12 5.283,17.303 6.697,18.717 12,13.414 17.303,18.717 18.717,17.303 13.414,12 '/%3E%3C/g%3E%3C/g%3E%3Cg id='Guides' style='display:none;'%3E%3C/g%3E%3C/svg%3E"); }
  .icon-close-only.black {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve' fill='black'%3E%3Cg id='Icons' style='opacity:1.0;'%3E%3Cg id='close'%3E%3Cpolygon id='x' style='fill-rule:evenodd;clip-rule:evenodd;' points='18.717,6.697 17.303,5.283 12,10.586 6.697,5.283 5.283,6.697 10.586,12 5.283,17.303 6.697,18.717 12,13.414 17.303,18.717 18.717,17.303 13.414,12 '/%3E%3C/g%3E%3C/g%3E%3Cg id='Guides' style='display:none;'%3E%3C/g%3E%3C/svg%3E"); }
  .icon-close-only.s14x14 {
    width: 14px;
    height: 14px; }
  .icon-close-only.s18x18 {
    width: 18px;
    height: 18px; }
  .icon-close-only.s32x32 {
    width: 32px;
    height: 32px; }
  .icon-close-only.s25x25 {
    width: 25px;
    height: 25px; }

.cursor-pointer {
  cursor: pointer; }

.select-users {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
  width: 250px;
  color: #222; }
  .select-users .select {
    border: none;
    border-radius: 4px;
    background: #eee;
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
    position: relative; }
    .select-users .select:after {
      position: absolute;
      font-family: "Font Awesome 5 Free";
      content: "\f107";
      font-weight: 900;
      top: 14px;
      right: 20px;
      width: 0;
      height: 0;
      color: #777; }
  .select-users .autocomplete-list {
    margin-top: 3px; }
  .select-users li {
    border: 0px;
    padding: 6px 10px; }
    .select-users li.active {
      background: #36a3f7;
      color: white; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.min-wd-0 {
  min-width: 0px; }
